import { useSearchParams } from 'next/navigation'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

export const useReferralCodeName = () => {
  const searchParams = useSearchParams()

  const referralCodeName = getLocalStorage('referral-code-name')

  if (referralCodeName) {
    return referralCodeName
  }

  const rfrrl = searchParams.get('rfrrl')

  if (!rfrrl) {
    return null
  }

  const name = String(rfrrl)

  setLocalStorage('referral-code-name', name)

  return name
}
