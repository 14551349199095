import { useEffect } from 'react'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useDataLayer } from '~/hooks/useDataLayer'
import { hash } from '~/utils/hash'

export const useAddUserInformationToDataLayer = () => {
  const { add } = useDataLayer()

  const { id: userId, email, phoneNumber } = useCurrentUserNullable()

  useEffect(() => {
    if (userId == null) {
      add({ ecommerce: null })

      return
    }

    const userPhoneNumber = phoneNumber != null ? hash(phoneNumber) : null

    add(
      {
        userEmail: hash(email ?? ''),
        userId,
        ...(userPhoneNumber != null && {
          userPhoneNumber,
        }),
      },
      'user-info',
    )

    add(
      {
        event: 'USER_LOGIN',
        ecommerce: {
          purchase: {
            actionField: {
              id: userId,
            },
            products: [
              {
                category: 'User',
                dimension2: userId,
                id: userId,
              },
            ],
          },
        },
      },
      'user-ecommerce-info',
    )
  }, [add, email, phoneNumber, userId])

  return null
}
