import(/* webpackMode: "eager", webpackExports: ["GlobalStyles"] */ "/home/runner/work/app-web/app-web/apps/web/src/app/(core)/components/GlobalStyles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/app-web/app-web/apps/web/src/app/(core)/components/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SetupEffects"] */ "/home/runner/work/app-web/app-web/apps/web/src/app/(core)/components/SetupEffects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledComponentsRegistry"] */ "/home/runner/work/app-web/app-web/apps/web/src/app/(core)/components/StyledComponentsRegistry.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/app-web/app-web/libraries/design-tokens/src/index.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/app-web/app-web/node_modules/react-medium-image-zoom/dist/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/app-web/app-web/node_modules/stream-chat-react/dist/scss/v2/index.scss");
