'use client'

import { useBreakpoints } from 'hooks'
import { useLayoutEffect } from 'react'
import { useClarityIdentifyEffect } from '~/domains/analytics/hooks/useClarityIdentifyEffect'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useUpdateIntercom } from '~/domains/intercom/hooks/useUpdateIntercom'
import { useUpdateIntercomFingerprint } from '~/domains/intercom/hooks/useUpdateIntercomFingerprint'
import { useReferralCodeName } from '~/domains/referral/hooks/useReferralCodeName'
import { useAddUserInformationToDataLayer } from '~/hooks/useAddUserInformationToDataLayer'
import { useAddUserToSentryEffect } from '~/hooks/useAddUserToSentryEffect'
import { useQueryParamInSessionStorageEffect } from '~/hooks/useQueryParamInSessionStorageEffect'
import { useSetupAnonymousFingerprintEffect } from '~/hooks/useSetupAnonymousFingerprintEffect'
import { useUpdateLocalStorageSettingsInvite } from '~/hooks/useUpdateLocalStorageSettingsInvite'

export const SetupEffects = () => {
  const { isSmallOnly } = useBreakpoints()
  const { update } = useIntercom()

  useSetupAnonymousFingerprintEffect()
  useQueryParamInSessionStorageEffect()
  useAddUserInformationToDataLayer()
  useAddUserToSentryEffect()
  useClarityIdentifyEffect()
  useUpdateLocalStorageSettingsInvite()
  useUpdateIntercom()
  useUpdateIntercomFingerprint()
  useReferralCodeName()
  useConventionCodeName()

  useLayoutEffect(() => {
    update({
      verticalPadding: isSmallOnly ? 80 : 20,
    })
  }, [isSmallOnly, update])

  return null
}
