import { pipe } from 'fp-ts/function'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { getSessionStorageQueryParams } from '~/utils/getSessionStorageQueryParams'
import { setSessionStorage } from '~/utils/sessionStorage/setSessionStorage'
import { getDefaultSessionStorage } from '../useSessionStorageParams'

/**
 * Extracts unique key-value pairs from URLSearchParams, keeping the latest occurrence of each key
 */
export const extractUniqueKeyValuePairs = (searchParams: URLSearchParams): URLSearchParams | null => {
  const params = typeof searchParams === 'string' ? new URLSearchParams(searchParams) : searchParams

  if (params.toString() === '') {
    return null
  }

  const uniqueEntries = Array.from(params.entries()).reduce<Record<string, string>>(
    (result, [key, value]) => ({
      ...result,
      [key]: value.trim(),
    }),
    {},
  )

  const uniqueParams = new URLSearchParams(uniqueEntries)

  if (params.toString() === '') {
    return null
  }

  return uniqueParams
}

export const useQueryParamInSessionStorageEffect = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const uniqueParams = extractUniqueKeyValuePairs(searchParams)

  const asPath = uniqueParams != null ? `${pathname}?${uniqueParams.toString()}` : pathname

  useEffect(() => {
    if (getSessionStorageQueryParams() != null) {
      return
    }

    const value = pipe(asPath, getDefaultSessionStorage, JSON.stringify)

    setSessionStorage('query-params', value)
  }, [asPath])
}
