'use client'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { type ReactNode, useEffect, useState } from 'react'
import { Apollo } from '~/clients/apollo'
import { Toasts } from '~/components/Toasts'
import { ThirdPartyAppProvider } from '~/domains/appNative/hooks/useThirdPartyApp'
import { AuthStateProvider } from '~/domains/auth/components/AuthStateProvider'
import { AssertMinimumRequirements } from '~/domains/error/AssertMinimumRequirements'
import { GenericError } from '~/domains/error/GenericError'
import { IntercomProvider } from '~/domains/intercom/components/IntercomProvider'
import { CurrentUserProvider } from '~/hooks/useCurrentUser'
import { LoadingStateProvider } from '~/hooks/useLoadingState'
import { ToastsProvider } from '~/hooks/useToasts'
import { LanguageProvider } from '~/i18n/hooks/useLanguage'

type Props = {
  children: ReactNode
}

export const Providers = ({ children }: Props) => {
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  if (!domLoaded) {
    return null
  }

  return (
    <Sentry.ErrorBoundary fallback={<GenericError />}>
      <AssertMinimumRequirements>
        <ApolloProvider client={Apollo}>
          <LanguageProvider>
            <IntercomProvider>
              <LoadingStateProvider>
                <ToastsProvider>
                  <ThirdPartyAppProvider>
                    <CurrentUserProvider>
                      <AuthStateProvider>
                        {children}
                        <Toasts />
                      </AuthStateProvider>
                    </CurrentUserProvider>
                  </ThirdPartyAppProvider>
                </ToastsProvider>
              </LoadingStateProvider>
            </IntercomProvider>
          </LanguageProvider>
        </ApolloProvider>
      </AssertMinimumRequirements>
    </Sentry.ErrorBoundary>
  )
}
