'use client'

import { media } from 'cdk'
import { createGlobalStyle } from 'styled-components'
import { GlobalStyle as UiGlobalStyle } from 'ui'

const AppGlobalStyle = createGlobalStyle`
  html, body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  body {
    flex-grow: 1;
  }

  ${media.sm`
    .intercom-namespace.intercom-namespace {
      .intercom-app > [class^="intercom-with-namespace"] {
        bottom: 80px;
      }
    }
  `}
`

export const GlobalStyles = () => (
  <>
    <UiGlobalStyle />
    <AppGlobalStyle />
  </>
)
